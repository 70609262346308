
.event-list {  
  padding: 10px;

  .event-list-item {      
    margin: 10px 0px;
    
    .event-list-item-header {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .language-picker {        
        margin-right: 10px;
      }
    }

    .event-list-item-screens {
      padding: 10px;
    }

    .event-list-item-controls {
      background-color: #f2f2f2;
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .event-list-item-controls-right > * {
        margin-left: 10px;
      }
    }
  } 
}

