
.challenge-list {  
  padding: 0px 20px 10px 20px;

  .challenge-list-item {      
      padding: 15px;
      margin: 10px 0px;

      .challenge-list-item-contents {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .challenge-list-item-actions {
          display: flex;
          align-items: center;

          .copy-button-wrapper {
            margin-right: 20px;
          }
        }
      }
  }

  .challenge-list-controls {
      padding: 10px 0px;
      display: flex;
      justify-content: flex-start;
  }
}

