
.team-container {  
  margin: 10px 0px;
  padding: 10px;

  h2 {
    margin: 0px 0px 10px 0px;
  }
}

.team-dialog.dialog-content {
  width: auto;  

  h2 {
    text-align: center;
  }

  .team-info {
    display: flex;

    .team-grid {
      max-height: 680px;
      overflow: scroll;
      width: 70%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      gap: 10px;
  
      .team-grid-item {
        background-color: #e8e8e8;
        width: 190px;
        height: 200px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
  
        .team-grid-item-header-contents {
          display: flex;
          justify-content: center;
          align-items: center;
  
          h3 {
            text-align: center;
            margin: 0px
          }
        }
  
        .team-member-list {
          width: 100%;
          margin-bottom: 5px;
          overflow: scroll;
  
          ul {
            margin: 0px;
  
            li {
              button {
                padding: 5px;
              }
            }
          }
        }

        .assign-players-button {

          padding: 3px 5px;
        }
      }
    }

    .unassigned-player-list {
      width: 30%;
      max-height: 500px;
      background-color: #e8e8e8;
      overflow: scroll;

      h3 {
        margin: 18px 0px 10px 0px;
        text-align: center;
      }

      .unassigned-player-item {
        padding: 0px 10px;
        display: flex;
        align-items: center;
      }
    }
  }


}



