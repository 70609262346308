
.customer-list {  

  .customer-list-item {
    .customer-list-item-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px 0px 15px;
      margin: 10px 0px;

      h2 {
        font-size: 1.5em;
      }

      .customer-list-item-link {
        text-decoration: none;
        width: 100%;
      }

      .customer-list-item-controls {
        display: flex;        
        flex-direction: row;
        align-items: center;

        > * {
          margin-right: 10px;
        }
        
        .customer-list-item-color {
          width: 40px;
          height: 40px;          
          border-radius: 5px;
        }
      }    
    }
        
  }

  .customer-list-controls {
      padding: 10px 0px;
      display: flex;
      justify-content: flex-start;
  }
}

.player-dialog.dialog-content {   
  width: auto;
  min-height: 600px;

  .player-lists {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .player-list-wrapper {
      padding: 13px;

      h3 {
        margin: 0px 0px 10px 0px;
      }

      &.active-player-list {
        width: 600px;
      }
  
      &.deactivated-player-list {
        width: 250px;
      }
  
      &.new-player-list {
        margin-top: 20px;
        width: 890px;
      }
  
      .player-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: center;
        gap: 10px;
        min-height: 200px;
        max-height: 550px;
        overflow: scroll;
    
        .player-list-item {
          width: 178px;
          background-color: #F0F0F0;
          padding: 5px 5px 5px 10px;
          border-radius: 3px;
  
          .player-list-item-contents {
            display: flex;
            align-items: center;
            justify-content: space-between;            
      
            .edit-button, .delete-button {
              padding: 5px;
            }
          }
    
        }
      }    
    }  
  }

  
}