
.screen-list-item {  
  margin: 10px 0px;

  .screen-list-item-header {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .screen-list-item-title {
      display: flex;
      align-items: center;

      > p {
        margin-right: 10px;
      }

      > div {
        display: flex;
      }
    }

  }

  .screen-list-item-details {

    .screen-component {
      background-color: #f2f2f2;
      margin: 10px;
      padding: 10px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .screen-component-header {
        width: 100%;
        display: flex;        
        align-items: center;
        justify-content: space-between;

        .screen-component-title {
          display: flex;
        }        
      }      
    }

    .add-component-controls {
      background-color: #f2f2f2;
      padding: 10px;
      > button {
        margin-right: 10px;
      }
    }
  }
}

.screen-title-component {
  .screen-title-component-content {
    display: flex;
  }
}


.screen-question-component {
  .screen-question-component-content {
    display: flex;
  }
}


.screen-rich-text-component {
  .screen-rich-text-component-content {
    margin-top: 10px;
    display: flex;
    align-items: center;

    > p {      
      margin: 0px;
    }

    .rich-text {
      margin: 0px 10px;
      background-color: #E1E1E1;
      padding: 1px 15px;
      border-radius: 10px;
      max-height: 150px;
      overflow: scroll;
    }

    .rich-text-editor {
      margin: 0px 10px;
    }
  }
}

.screen-image-component {
  .screen-image-component-content {
    display: flex;
    label {
      display: flex;
    }
    .component-image {
      width: 150px;
      margin-right: 10px;
    }

  }
}