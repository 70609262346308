.question-dialog.dialog-content {
  width: 600px;

  .settings-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
  }

  .basic-settings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .answer {
      width: 300px;
    }
    
    .points {
      width: 100px;
    }

    .incorrect-deduction-points {
      width: 180px;
    }
  }

  .hint-list {
    
    .hint-list-item-wrapper {
      margin-bottom: 5px;

      flex-direction: column;
      display: flex;
      .hint-list-item {
        margin-bottom: 10px;
        > div {
          margin-right: 5px;
        }

        .hint-textfield {
          width: 170px;
        }

        .hint-number {
          width: 100px;
        }
      }   
    }    
  }

  .answer-list {
    .answer-list-item-wrapper {
      margin-bottom: 5px;
    }
  }
}