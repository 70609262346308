
.login-form-wrapper {        
  padding: 20px 30px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 23px;
  border-radius: 30px;
}

.login-form {
  padding: 20px 10px 10px 10px;
  text-align: center; 
}